body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button {
  border: none;
  transition-duration: 0.4s;
  color: white;
  padding: 6px 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 200px;
}

.grid1 {
  grid-area: header;
}

.grid2 {
  grid-area: menu;
}

.grid3 {
  grid-area: main;
}

.grid4 {
  grid-area: footer;
}

.grid-container {
  display: grid;
  grid-template-areas:
    'header'
    'menu'
    'main'
    'footer';
  grid-gap: 10px;
  background-color: white;
  padding: 10px;
  grid-template-columns: 1200px 1fr;
}

.headerCenter {
  text-align: center;
}